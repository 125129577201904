<template>
	<nuxt-link class="logo" :to="{ path: localePath('/'), hash: route.name.startsWith('index') ? '#top' : '' }" />
</template>

<script setup>
const route = useRoute();
</script>

<style lang="scss" scoped>
.logo {
	display: block;
	background: url('~/assets/images/logo-cafe-marcella-full.png') no-repeat center center;
	background-size: 100%;
	width: 600px;
	height: 171px;
	margin: 0 auto 40px;
	max-width: 75vw;
}

.subpage .logo {
	display: block;
	background: url('~/assets/images/logo-cafe-marcella-small.png') no-repeat center center;
	background-size: 100%;
	width: 500px;
	height: 142px;
}

@media (max-width: 800px) {
	.logo {
		margin: 0 auto 20px;
	}

	.subpage .logo {
		margin: 0 auto 10px;
	}
}

@media (max-width: 580px) {
	.logo,
	.subpage .logo {
		margin: 0 auto;
	}
}
</style>
